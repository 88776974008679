<template>
  <div
    class="iframe-wrapper"
    :class="`${isLandscapeGame ? 'landscape-game' : 'portrait-game'}`"
  >
    <div class="iframe-container">
      <iframe
        :src="`https://minigames.ecogamecenter.net/games/${$route.params.gameId}/?token=${token}`"
        class="iframe"
        width="100%"
        height="100%"
        scrolling="no"
        allowfullscreen
        ref="iframeWrapper"
        id="iframe"
      ></iframe>
    </div>
    <i
      class="bx bx-fullscreen bx-burst"
      v-if="!isFullscreenMode"
      @click="goFullscreen"
    ></i>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isLandscapeGame: false,
      isFullscreenMode: false,
    };
  },
  created() {
    if (this.$route.params.orientation === "portrait")
      this.isLandscapeGame = false;
    else this.isLandscapeGame = true;
  },
  mounted() {
    this.goFullscreen();
    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        this.isFullscreenMode = true;
      } else {
        this.isFullscreenMode = false;
      }
    });

    //set size of canvas inside the iframe to 100% of container
    // this not working on localhost because of domain name (same domain name need to access the inner canvas of iframe)
    // setTimeout(() => {
    //   let iframe = document.getElementById("iframe");
    //   console.log({ iframe });
    //   let html = iframe.contentWindow.document.querySelector("html");
    //   let body = iframe.contentWindow.document.querySelector("body");
    //   let canvas = iframe.contentWindow.document.getElementById("unity-canvas");
    //   console.log({ html }, { body }, { canvas });
    //   html.style.width = "100%";
    //   html.style.height = "100%";
    //   body.style.width = "100%";
    //   body.style.height = "100%";
    //   canvas.style.width = "100%";
    //   canvas.style.height = "100%";
    // }, 2000);
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    goFullscreen() {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem
          .requestFullscreen()
          .then(() => {
            this.isFullscreenMode = true;
          })
          .catch(() => {
            this.isFullscreenMode = false;
          });
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem
          .webkitRequestFullscreen()
          .then(() => {
            this.isFullscreenMode = true;
          })
          .catch(() => {
            this.isFullscreenMode = false;
          });
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem
          .msRequestFullscreen()
          .then(() => {
            this.isFullscreenMode = true;
          })
          .catch(() => {
            this.isFullscreenMode = false;
          });
      }
      //
    },
  },
};
</script>

<style lang="scss">
.iframe-wrapper {
  position: relative;
  &.landscape-game {
    @media only screen and (orientation: portrait) {
      width: 100vh;
      height: 100vw;
      transform: translateX(100vw) rotate(90deg);
      transform-origin: top left;
    }
    @media only screen and (orientation: landscape) {
      height: 100vh;
      width: 100vw;
    }
  }
  &.portrait-game {
    height: 100vh;
    max-width: 60vh;
    margin: auto;
  }
  .iframe-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    .iframe {
      border: none;
    }
  }
  i {
    position: absolute;
    font-size: 30px;
    bottom: 3%;
    left: 2%;
    z-index: 1000;
    color: white;
    cursor: pointer;
  }
}
</style>
